import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { Flex, Box, Heading, Text } from 'rebass'
import { Container, Hero, Layout } from '../components'
import config from '../utils/siteConfig'

const ContactPage = ({ data }) => (
  <Layout>
    <Helmet>
      <title>
        {data.contentfulPage.title} – {config.siteTitle}
      </title>
      <meta name="description" content={data.contentfulPage.title} />
      <meta
        property="og:title"
        content={`${data.contentfulPage.title} – ${config.siteTitle}`}
      />
      <meta property="og:url" content={config.siteUrl} />
    </Helmet>

    <Hero
      title={data.contentfulPage.title}
      excerpt={data.contentfulPage.excerpt.excerpt}
      image={data.contentfulPage.image}
    />

    <Box>
      <Container py={[4, 5]} px={[3, 4]} maxWidth="56em">
        <Heading
          fontWeight="normal"
          mb={[3, null, 4, 5]}
          fontSize={[3, 4, 5]}
          textAlign="center"
        >
          Astanga Jooga Lappi ry:n hallitus
        </Heading>
        <Text fontSize={[1, 2]} textAlign={['center', null, 'left']}>
          <Flex flexWrap="wrap" mb={[2, 3, 2]}>
            <Box width={[1, null, 1 / 3]}>
              <strong>Elisa Mäki</strong>
            </Box>
            <Box width={[1, null, 2 / 3]}>
              Puheenjohtaja / anna.elisa.maki @ gmail.com
            </Box>
          </Flex>
          <Flex flexWrap="wrap" mb={[2, 3, 2]}>
            <Box width={[1, null, 1 / 3]}>
              <strong>Aksana Kurola</strong>
            </Box>
            <Box width={[1, null, 2 / 3]}>Varapuheenjohtaja</Box>
          </Flex>
          <Flex flexWrap="wrap" mb={[2, 3, 2]}>
            <Box width={[1, null, 1 / 3]}>
              <strong>Leena Rantamaula</strong>
            </Box>
            <Box width={[1, null, 2 / 3]}>Sihteeri</Box>
          </Flex>
          {/* <Flex flexWrap="wrap" mb={[2, 3, 2]}>
            <Box width={[1, null, 1 / 3]}>
              <strong>Aksana Kurola</strong>
            </Box>
            <Box width={[1, null, 2 / 3]}>Viikonloppukurssit</Box>
          </Flex> */}
          {/* <Flex flexWrap="wrap" mb={[2, 3, 2]}>
            <Box width={[1, null, 1 / 3]}>
              <strong>Sebastien Changenot</strong>
            </Box>
            <Box width={[1, null, 2 / 3]}>
              Taloudenhoitaja, sebunet @ gmail.com
            </Box>
          </Flex> */}
          <Flex flexWrap="wrap" mb={[2, 3, 2]}>
            <Box width={[1, null, 1 / 3]}>
              <strong>Veera Antikainen</strong>
            </Box>
            <Box width={[1, null, 2 / 3]}>Tiedottaja</Box>
          </Flex>
        </Text>

        <Heading
          fontWeight="normal"
          mb={[3, null, 4, 5]}
          mt={[5, null, 6]}
          fontSize={[3, 4, 5]}
          textAlign="center"
        >
          Ohjaajat
        </Heading>
        <Text fontSize={[1, 2]} textAlign="center">
          <Flex justifyContent="center">
            <Box px={2}>
              <strong>Aksana Kurola</strong>
            </Box>
            <Box px={2}>
              <strong>Mervi Manninen</strong>
            </Box>
            <Box px={2}>
              <strong>Veera Antikainen</strong>
            </Box>
          </Flex>
        </Text>

        <Box mt={[5, 6]}>
          <Text textAlign="center" fontSize={[1, 2]}>
            <strong>Tiedustelut:</strong> astanga.lappi@gmail.com
          </Text>
          <Text textAlign="center" fontSize={[1, 2]}>
            <strong>Ilmoittautumiset:</strong> jani.roine@gmail.com
          </Text>
        </Box>
      </Container>
    </Box>
  </Layout>
)

export default ContactPage

export const query = graphql`
  query pageQuery {
    contentfulPage(slug: { eq: "yhteystiedot" }) {
      title
      excerpt {
        excerpt
      }
      image {
        fluid(maxWidth: 1440) {
          ...GatsbyContentfulFluid_tracedSVG
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
